import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  Router,
  Event,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import { v4 as uuidv4 } from 'uuid';

// resolver imports
import { CompanyDetailsResolver } from '../_common/resolvers/company-details-resolver';
import { CompanyUpdateResolver } from '../_common/resolvers/company-update-resolver';
import { SearchResultsResolver } from '../_common/resolvers/search-results-resolver';
import { UserResolver } from '../_common/resolvers/user-resolver';

// utility imports
import { CanActivateUpdateRouteGuard } from '../_common/guards/can-activate-update-route-guard';
import { RoleCanActivateRouteGuard } from '../_common/guards/role-can-activate-route-guard';
import { CanActivateMyUpdatesRouteGuard } from '../_common/guards/can-activate-my-updates-route-guard';
import { RemoveOldInternalRouteGuard } from '../_common/guards/remove-old-internal-route-guard';
import { PendingChangesGuard } from '../_common/guards/pending-changes-guard';
import { DeploymentContext } from '../_common/utilities/deployment-context/deployment-context';
import { JnjErrorHandler } from '../_common/utilities/jnj-error-handler/jnj-error-handler';

// top-level components
import { AcsComponent } from './sso/acs.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CompanyUpdateComponent } from './company-update/company-update.component';
import { HealthCheckComponent } from './health-check/health-check.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { MyUpdatesComponent } from './my-updates/my-updates.component';
import { PreferencesComponent } from './user/components/prefereneces/preferences.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SeoService } from '../_common/services/seo/seo.service';
import { SsoComponent } from './sso/sso.component';
import { UpdateComponent } from './update-generic/update.component';
import { RoleGroups } from 'company-finder-common';
import { TitleAndMetadata } from '../_common/utilities/title-and-metadata/title-and-metadata';

const routes: Routes = [
  {
    path: 'internal',
    children: [
      {
        path: '',
        component: SsoComponent,
      },
      {
        path: '**',
        canActivate: [RemoveOldInternalRouteGuard],
        // This component will never load since the guard will *always* redirect out of internal
        component: MainComponent,
      },
    ],
  },
  {
    path: '',
    resolve: { user: UserResolver },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'details/:id',
        component: CompanyDetailsComponent,
        resolve: { company: CompanyDetailsResolver },
        canActivate: [RoleCanActivateRouteGuard],
        data: { permittedRoleGroup: RoleGroups.AuthenticatedUsers },
      },
      {
        // Allows an unqualified route to update a user's company (assumes there will never be a Company with the name "update")
        path: 'company/update', // this route seems necessary to allow an unqualified "company/update" or "company/update/" url to work
        redirectTo: 'company/update/', // this seems to successfully identify company/update/:name rather than company/:name
      },
      {
        // JUniverse users will land on this unqualified link, which will ensure the JUniverse JWT is processed
        // and then build the qualified route to redirect to.
        path: 'company/update/',
        component: UpdateComponent,
      },
      {
        path: 'company/update/:name',
        component: CompanyUpdateComponent,
        resolve: { company: CompanyUpdateResolver },
        canActivate: [RoleCanActivateRouteGuard, CanActivateUpdateRouteGuard],
        canDeactivate: [PendingChangesGuard],
        data: { permittedRoleGroup: RoleGroups.UpdateUsers },
      },
      {
        path: 'company/:name',
        component: CompanyDetailsComponent,
        resolve: { company: CompanyDetailsResolver },
        canActivate: [RoleCanActivateRouteGuard],
        data: { permittedRoleGroup: RoleGroups.AuthenticatedUsers },
      },
      { path: 'login/debug', component: LoginComponent },
      { path: 'login', component: LoginComponent },
      { path: 'ping', component: SsoComponent },
      { path: 'acs', component: AcsComponent },
      { path: 'health-check', component: HealthCheckComponent },
      // FUTURE - now that there is no concept of public search,
      // it may be worth removing these (J&J is removing all links to these)
      {
        path: 'location/:location', // See ADJQ-187 for more context
        component: SearchResultsComponent,
        resolve: { searchResults: SearchResultsResolver },
        canActivate: [RoleCanActivateRouteGuard],
        data: { permittedRoleGroup: RoleGroups.AuthenticatedUsers },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'filter/:filter', // Similar to locations/ADJQ-187 above
        component: SearchResultsComponent,
        resolve: { searchResults: SearchResultsResolver },
        canActivate: [RoleCanActivateRouteGuard],
        data: { permittedRoleGroup: RoleGroups.AuthenticatedUsers },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'my-updates',
        component: MyUpdatesComponent,
        canActivate: [
          RoleCanActivateRouteGuard,
          CanActivateMyUpdatesRouteGuard,
        ],
        data: { permittedRoleGroup: RoleGroups.InternalUsers },
        resolve: { _user: UserResolver },
      },
      {
        path: 'search-results',
        component: SearchResultsComponent,
        resolve: { searchResults: SearchResultsResolver },
        canActivate: [RoleCanActivateRouteGuard],
        data: { permittedRoleGroup: RoleGroups.AuthenticatedUsers },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'user/preferences',
        component: PreferencesComponent,
        canActivate: [RoleCanActivateRouteGuard],
        data: { permittedRoleGroup: RoleGroups.InternalUsers },
        resolve: { _user: UserResolver },
      },
      {
        path: '',
        component: MainComponent,
      },
      { path: '**', redirectTo: '/' },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
  providers: [
    CompanyDetailsResolver,
    CompanyUpdateResolver,
    SearchResultsResolver,
    UserResolver,
  ],
})
export class AppRoutingModule {
  /** Introduced to help debug origination of NavigationErrors if Navigator actually builds a bad one itself
   * versus deep links in.
   */
  private previousUrl: string;

  constructor(
    router: Router,
    context: DeploymentContext,
    seoService: SeoService,
    titleAndMetadata: TitleAndMetadata,
    errorHandler: JnjErrorHandler
  ) {
    /**
     * Push route changes up to the parent window.
     * If the application isn't contained in a parent window (and should be), force it to be.
     * https://jira.jnj.com/browse/ADJQ-19
     */
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const url = (<NavigationEnd>event).url;
        this.previousUrl = url;

        // Maintain backwards compatibility with old fragment routes
        if (url.startsWith('/#/')) {
          // The first two paths after splitting will be '' and '#'. The rest will be a potentially vaild route
          const paths = url.split('/').slice(2);
          return router.navigate(paths, { replaceUrl: true });
        }

        // FUTURE: This may no longer be needed, but we don't have the time to re-analyze at the moment
        // It's possible this pre-dated switching the app over to use the HashLocationStrategy itself.
        if (url === '/#internal') {
          // Since the login page router is "hidden" (not exposed anywhere in the UI)
          // we need to get a user there based on entering an absolute URL into the address bar.
          // The hosting page already passes down URL fragments/hash, and converts those to paths.
          // But any inbound path will be handled by the deployment webserver and might not reach the Angular App.
          // This uses a double nested hash (http://127.0.0.1:8082/assets/IntegrationWithDrupal/Drupal-MockView.html#/#internal)
          // TODO: Might need to revisit this as we confirm deep linking is working correctly in deployment
          //  and not just in the development environment.
          return router.navigate(['ping']);
        }

        // Add a canonical tag to suggest search engines attribute content in this app to the JLABS domain.
        // See https://support.google.com/webmasters/answer/139066?hl=en for more info.
        seoService.addCanonicalTag(url);

        // Set a default title, which might be overidden by individual views in their ngInit, which happens later
        // For now, assume the default title will be covered by the hosting page.
        titleAndMetadata.setPageTitle('');
        titleAndMetadata.upsertMetaTagDescription(
          'Browse the JLABSNavigator to learn more about exciting companies that are driving innovation with JLABS.'
        );

        context.ensureHostedNavigation(url);
        // Don't bother to scroll to top if we're going to scroll elsewhere
        if (!url.startsWith('/search-results')) {
          context.scrollToTopOnNextUpdate = true;
        }
      } else if (event instanceof NavigationError) {
        // The actual NavigationError never makes it to the JnjErrorHandler, so we lose the full URL context.
        // Throwing a new error here with that context will result in JnjErrorHandler trapping 2 errors.
        // Hack: Augment the error, manually invoke the error handler with desired parameters,
        //  and evolve the error handler to ignore the "original" error fingerprint... there really should be a better solution!
        event.error.message += ` when accessing url: ${event.url}, with previousUrl: ${this.previousUrl}`;

        errorHandler.handleError(
          event.error,
          'Navigation Error',
          true,
          uuidv4(),
          true // don't toast it to the user
        );
        // ... and just put the user on the landing page
        return router.navigate(['/']);
      }
    });
  }
}
