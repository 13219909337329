import { ActivatedRoute, Router } from '@angular/router';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { ComponentBase } from '../../_common/components/_component.base';

// vendor imports

// model imports
import {
  Company,
  LocalizedTextIds,
  isNewCompany,
  hasDealsWithJnJ,
  convertToValidWebsiteUrl,
  LocPrefix,
  PublicOverrides,
} from 'company-finder-common';
import { TabConfig, TabInfo } from '../../_common/utilities/tabs/tab.util';

// service imports
import {
  Breadcrumb,
  BreadcrumbsService,
} from '../../_common/services/breadcrumbs/breadcrumbs.service';
import { CompanyService } from '../../_common/services/company/company.service';
import { WebAnalyticsService } from '../../_common/services/web-analytics/web.analytics';

// utility imports
import { ApplicationContext } from '../../_common/utilities/application-context/application-context';
import { AuthnService } from '../../_common/services/authn/authn.service';
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';
import {
  displayMilestone,
  hasMilestonesOfType,
  milestoneSuffixKeys,
  MilestoneType,
  MilestoneValue,
  milestoneLayoutInfoArray,
} from '../../_common/utilities/blue-knight/blue-knight.util';
import { UserService } from '../../_common/services/user/user.service';
import { TitleAndMetadata } from '../../_common/utilities/title-and-metadata/title-and-metadata';
import { DownloadComponent } from './components/download/download.component';
import { StatusItem } from '../../_common/components/status-list/StatusItem';

@Component({
  selector: 'company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent extends ComponentBase implements OnInit {
  // public properties
  public company: Company;
  // Clone, because we initialize the properties, and don't want others to overwrite or add to our collection.
  public milestoneLayoutInfos;
  public trlLayoutInfo = {
    label: 'TRL',
    properties: [],
    propertyRoot: 'Trl',
    type: MilestoneType.TRL,
  };
  public futureEmployeeInfo = {
    label: this.Loc(LocalizedTextIds.BlueKnightInformationFutureEmployeeNeeds),
    properties: [],
    propertyRoot: 'NumEmpFutureNeeds',
    type: MilestoneType.EmployeeNeeds,
  };
  public milestonePrefixes = [
    {
      prop: 'twelveMonth',
      value: this.Loc(LocalizedTextIds.CompanyDetailsOneYear),
    },
    {
      prop: 'twentyFourMonth',
      value: this.Loc(LocalizedTextIds.CompanyDetailsTwoYears),
    },
    {
      prop: 'thirtySixMonth',
      value: this.Loc(LocalizedTextIds.CompanyDetailsThreeYears),
    },
    {
      prop: 'fortyEightMonth',
      value: this.Loc(LocalizedTextIds.CompanyDetailsFourYears),
    },
    {
      prop: 'sixtyMonth',
      value: this.Loc(LocalizedTextIds.CompanyDetailsFiveYears),
    },
  ];

  public MilestoneValues = MilestoneValue;
  public Priorities = Priority;
  public isFollowing = false;
  public TabConfigs = TabConfig;
  public tabs = [
    {
      // This value is used to make the tab highlight (the red line under the text)
      // the same approximate length as the text it is highlighting.
      // FUTURE: Consider a fontmetric, programmatic approach
      highlightWidth: 77,
      label: this.Loc(LocalizedTextIds.CompanyDetailsPublicInfo),
      value: TabConfig.PUBLIC,
    },
  ];
  // private properties
  private _activeTab: TabInfo = this.tabs[0];

  public get includeInPublicView(): PublicOverrides {
    return this._deploymentContext.featureSwitches.includeInPublicView;
  }

  public constructor(
    dc: DeploymentContext,
    private _applicationContext: ApplicationContext,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authnService: AuthnService,
    private _breadcrumbsService: BreadcrumbsService,
    private _companyService: CompanyService,
    private _userService: UserService,
    private _webAnalyticsService: WebAnalyticsService,
    private titleAndMetadata: TitleAndMetadata
  ) {
    super(dc);
    this.milestoneLayoutInfos =
      this._deploymentContext.getMilestoneLayoutInfoArrayCopy(
        milestoneLayoutInfoArray
      );
  }

  // public getters
  public get currencyBlueKnight(): string {
    return this.company.currencyBlueKnight || 'USD';
  }

  public get isDownloadDocumentEnabled(): boolean {
    const documentGeneration = this._deploymentContext.documentGenerationConfig;
    return documentGeneration.documentDownloadEnabled || false;
  }

  public get showAdditionalContacts(): boolean {
    return (this.isInternalView && !!this.company.jpalContact) ||
      this.company.siteHead
      ? true
      : false;
  }

  public get showPrimaryAreaData(): boolean {
    return this.company.primarySector ||
      this.company.primarySubSector?.length > 0 ||
      this.company.primaryFocus ||
      this.company.primarySectorDetail
      ? true
      : false;
  }

  public get showSecondaryAreaData(): boolean {
    return this.company.secondarySector ||
      this.company.secondarySubSector?.length > 0  ||
      this.company.secondaryFocus ||
      this.company.secondarySectorDetail
      ? true
      : false;
  }

  public get showSimpleTabUi(): boolean {
    return this.isInternalOrBardaView;
  }

  public get showTrlSection(): boolean {
    if (this.company.isBlueKnight) {
      return this.company.trl ||
        this.company.twelveMonthTrl ||
        this.company.twentyFourMonthTrl ||
        this.company.thirtySixMonthTrl ||
        this.company.fortyEightMonthTrl ||
        this.company.sixtyMonthTrl
        ? true
        : false;
    }
    return false;
  }

  public get showFutureHeadcountNeeds(): boolean {
    if (this.company.isBlueKnight) {
      return this.isDefined(this.company.numEmpFutureNeeds) ||
        this.isDefined(this.company.twelveMonthNumEmpFutureNeeds) ||
        this.isDefined(this.company.twentyFourMonthNumEmpFutureNeeds) ||
        this.isDefined(this.company.thirtySixMonthNumEmpFutureNeeds) ||
        this.isDefined(this.company.fortyEightMonthNumEmpFutureNeeds) ||
        this.isDefined(this.company.sixtyMonthNumEmpFutureNeeds)
        ? true
        : false;
    }
    return false;
  }

  public get suppressStealthMode(): boolean {
    return !this.isInternalView || !this.company || !this.company.isStealthMode;
  }

  public get showLeadProductStage(): boolean {
    return (
      (this.featureSwitches.enableLeadProductStage ||
        this.featureSwitches.includeInPublicView.leadProductStage) &&
      this.company.currentRdStage?.length > 0
    );
  }

  public get statusItems(): StatusItem[] {
    const items: StatusItem[] =
      this.company.statuses
        ?.filter(
          (status) =>
            !(
              this._deploymentContext.companyStatusIcon &&
              status === this._deploymentContext.statusForCompanyIcon
            )
        )
        .map(
          (status) =>
            new StatusItem(
              this._deploymentContext.getStatusDisplayName(status),
              this._deploymentContext.getStatusTooltip(status)
            )
        ) ?? [];

    if (this.company.isQfcWinner) {
      items.push(new StatusItem(this.Loc(this.LocalizedTextIds.QFCAwardee)));
    }

    if (this.company.isBlueKnight) {
      items.push(
        new StatusItem(
          this.Loc(this.LocalizedTextIds.BlueKnight),
          null,
          '/assets/detail/blue-knight.png',
          18,
          14
        )
      );
    }

    return items;
  }

  public get companyStatuses(): string[] {
    return this._deploymentContext.getStatusDisplayNames(this.company.statuses);
  }

  // public methods
  public async downloadWordDocument(company: Company): Promise<void> {
    this.hideDownloadMenu();
    const response = await this._companyService.getWordData(company);
    this._applicationContext.saveDocument(company.name, response, 'docx');
    this._webAnalyticsService.trackEvent('export-company-details-as-word', {
      category: 'Export',
      label: company.name,
    });
  }

  public async downloadPdf(company: Company): Promise<void> {
    this.hideDownloadMenu();
    const response = await this._companyService.getPdfData(company);
    this._applicationContext.saveDocument(company.name, response, 'pdf');
    this._webAnalyticsService.trackEvent('export-company-details-as-pdf', {
      category: 'Export',
      label: company.name,
    });
  }

  public handleTabClick(tab: TabInfo): void {
    this._activeTab = tab;
  }

  public displayMilestone(milestoneValue: MilestoneValue): boolean {
    return displayMilestone(this.company, milestoneValue);
  }

  public hasMilestonesOfType(milestoneType: MilestoneType): boolean {
    return hasMilestonesOfType(this.milestoneLayoutInfos, milestoneType);
  }

  public initializeMilestoneLayoutInfo(): void {
    this.milestoneLayoutInfos.forEach((milestoneLayoutInfo) => {
      milestoneSuffixKeys.forEach((suffixKey, index) => {
        const monthCount = index * 12 + 12;
        const companyIndex = `${milestoneLayoutInfo.propertyRoot}${monthCount}Month`;

        milestoneLayoutInfo.properties.push({
          milestoneValue: MilestoneValue[MilestoneValue[monthCount]],
          label: this.Loc(suffixKey),
          value: this.company[companyIndex],
          propertyRoot: milestoneLayoutInfo.propertyRoot,
        });
      });
    });

    this.trlLayoutInfo.properties.push({
      milestoneValue: MilestoneValue.Initial,
      label: this.Loc(LocalizedTextIds.CompanyDetailsAtAcceptance),
      value: this.company.trl,
    });

    this.futureEmployeeInfo.properties.push({
      milestoneValue: MilestoneValue.Initial,
      label: this.Loc(LocalizedTextIds.CompanyDetailsAtAcceptance),
      value: this.company.numEmpFutureNeeds,
    });

    this.milestonePrefixes.forEach((prefix, index) => {
      const trlProp = `${prefix.prop}${this.trlLayoutInfo.propertyRoot}`;
      const employeeProp = `${prefix.prop}${this.futureEmployeeInfo.propertyRoot}`;
      const trlValue = this.company[trlProp];
      const employeeValue = this.company[employeeProp];

      this.trlLayoutInfo.properties.push({
        milestoneValue: MilestoneValue[MilestoneValue[index * 12 + 12]],
        label: prefix.value,
        value: trlValue,
      });
      this.futureEmployeeInfo.properties.push({
        milestoneValue: MilestoneValue[MilestoneValue[index * 12 + 12]],
        label: prefix.value,
        value: employeeValue,
      });
    });
  }

  public isShowingTabContent(value: TabConfig): boolean {
    return this._activeTab.value === value;
  }

  public async setFollowing(isFollowing: boolean): Promise<void> {
    this.isFollowing = isFollowing;
    return this._userService.setFollowingCompany(this.company.id, isFollowing);
  }

  public async ngOnInit(): Promise<void> {
    this.company = this._route.snapshot.data.company;

    // We don't have a full 404 set up, this is just to protect against a user pasting in the URL of a
    // company that they are not allowed to see
    if (!this.company) {
      this._router.navigate(['/']);
      return;
    }

    this.titleAndMetadata.setPageTitle(this.company.name);

    // eslint-disable-next-line max-len
    const primaryAreas = [
      this.company.primarySector,
      this.company.primarySubSector,
      this.company.primaryFocus,
      this.company.primarySectorDetail,
    ].filter((el) => el);
    const locationSummary = this.company.locations.reduce(
      (displayString, location) => {
        if (displayString.length > 0) {
          displayString += this.Loc(LocalizedTextIds.CompanyDetailsAnd);
        }

        // TODO ADJQ-1654 -- This is for the page metadata. Determine what J&J would like done with this in Universal
        if (location.locationStatuses?.includes('Current')) {
          if (
            location.name === this.Loc(LocalizedTextIds.CompanyDetailsVirtual)
          ) {
            displayString += this.Loc(
              LocalizedTextIds.CompanyDetailsAVirtualCurrent
            );
          } else {
            displayString += this.Loc(
              LocalizedTextIds.CompanyDetailsACurrent,
              location.name
            );
          }
        } else {
          // this will always be "Alumni" for now.
          const locStatus =
            this.LocWithPrefix(
              location.locationStatuses ? location.locationStatuses[0] : '',
              LocPrefix.LocStatus
            )?.toLocaleLowerCase() ?? '';
          if (
            location.name === this.Loc(LocalizedTextIds.CompanyDetailsVirtual)
          ) {
            displayString += this.Loc(
              LocalizedTextIds.CompanyDetailsAVirtual,
              locStatus
            );
          } else {
            displayString += this.Loc(
              LocalizedTextIds.CompanyDetailsAnOf,
              locStatus,
              location.name
            );
          }
        }

        return displayString;
      },
      ''
    );

    this.titleAndMetadata.upsertMetaTagDescription(
      this.Loc(
        LocalizedTextIds.CompanyDetailsTheCompany,
        this.company.name,
        locationSummary,
        primaryAreas
          .reverse()
          .join(this.Loc(LocalizedTextIds.CompanyDetailsWithin))
      )
    );

    const breadcrumb: Breadcrumb = {
      id: 'detail',
      name: this.company.name,
      action: undefined,
    };
    // Add this breadcrumb
    this._breadcrumbsService.addOrReplaceBreadcrumb(breadcrumb);
    // Is there a search breadcrumb?
    if (
      this._breadcrumbsService.breadcrumbs[
        this._breadcrumbsService.breadcrumbs.length - 2
      ].id !== 'search'
    ) {
      // If not, insert one.
      this._breadcrumbsService.insertBreadcrumb(
        this._breadcrumbsService.breadcrumbs.length - 1,
        this._breadcrumbsService.searchBreadcrumb
      );
    } else {
      // If so, make sure it's up to date.
      this._breadcrumbsService.updateBreadcrumb(
        this._breadcrumbsService.searchBreadcrumb
      );
    }

    this.isFollowing =
      this.isInternalView &&
      (await this._userService.isFollowingCompany(this.company.id));

    if (this.isInternalView) {
      this.tabs.push({
        highlightWidth: 145,
        label: this.Loc(LocalizedTextIds.CompanyDetailsInternalJJInfo),
        lockIcon: true,
        value: TabConfig.JNJ_ONLY,
      } as TabInfo);
    }

    if (this.isInternalOrBardaView) {
      if (this.company.isBlueKnight) {
        this.tabs.push({
          blueKnightIcon: true,
          highlightWidth: 242,
          label: this.Loc(LocalizedTextIds.CompanyDetailsInternalBKOverview),
          lockIcon: true,
          value: TabConfig.BLUE_KNIGHT,
        } as TabInfo);
        this.tabs.push({
          blueKnightIcon: true,
          highlightWidth: 252,
          label: this.Loc(LocalizedTextIds.CompanyDetailsInternalBKMilestones),
          lockIcon: true,
          value: TabConfig.BLUE_KNIGHT_MILESTONES,
        } as TabInfo);
      }

      this.initializeMilestoneLayoutInfo();
    }
  }

  @HostListener('window:click', ['$event'])
  protected onClick(): void {
    this.hideDownloadMenu();
  }

  @ViewChild(DownloadComponent) downloadButton: DownloadComponent;

  public hideDownloadMenu(): void {
    this.downloadButton.showMenu = false;
  }

  public get tagsSorted(): string[] {
    return this.company.tags.sort((a, b) =>
      a.localeCompare(b, undefined, { sensitivity: 'base' })
    );
  }

  public get isNew(): boolean {
    return this.company && isNewCompany(this.company);
  }

  public get hasInternalCompanyDetails(): boolean {
    return !!this.company.keyMgmtAndAdvBm || !!this.company.keyDifferentiation;
  }

  public get hasDealsWithJnJ(): boolean {
    return hasDealsWithJnJ(this.company);
  }

  public get hasProgressUpdate(): boolean {
    return (
      !!this.company.progressCreatedDate ||
      !!this.company.progressSubject ||
      !!this.company.progressUpdate
    );
  }

  public get hasRelatedRecords(): boolean {
    return (
      this.company.deals?.length > 0 ||
      this.company.opportunities?.length > 0 ||
      this.company.funding?.length > 0
    );
  }

  public get isFollowEnabled(): boolean {
    return this.featureSwitches.enableFollow;
  }

  public get isInternalView(): boolean {
    return this._authnService.isInternal;
  }

  public get isInternalOrBardaView(): boolean {
    return this._authnService.isInternalOrBARDA;
  }

  public get priority(): Priority {
    if (!this.company.priority) {
      return null;
    } else if (this.company.priority.match(/low/gi)) {
      return Priority.low;
    } else if (this.company.priority.match(/medium/gi)) {
      return Priority.medium;
    } else if (this.company.priority.match(/high/gi)) {
      return Priority.high;
    } else {
      return null;
    }
  }

  public get priorityDisplay(): string {
    if (!this.company.priority) {
      return null;
    } else {
      return this.company.priority.substring(4);
    }
  }

  public get jForceUrlForProgessUpdate(): string {
    // eslint-disable-next-line prettier/prettier
    return `${this._deploymentContext.rawConfig.jforceIntegration.jforceUrl}/${this.getJForceIDFromDataLakeID(
      this.company.opportunityIdPrimary
    )}?srPos=1&srKp=006`;
  }

  public get jForceUrlForOrgLevel(): string {
    // eslint-disable-next-line prettier/prettier
    return `${this._deploymentContext.rawConfig.jforceIntegration.jforceUrl}/${this.getJForceIDFromDataLakeID(
      this.company.id
    )}`;
  }

  public get validCompanyWebsiteUrl(): string {
    return convertToValidWebsiteUrl(this.company.website);
  }

  /** *************************************************************************
   * FUTURE: Code below here can be removed are commented out once we remove
   * the diagnostics dump.  Or maybe we enable that as a query string parameter...
   */
  public get properties(): string[] {
    if (!this.company) {
      return [];
    }
    const props: string[] = [];
    Object.keys(this.company).forEach((key) => {
      props.push(key);
    });
    return props;
  }

  public isArrayValue(prop: string): boolean {
    return Array.isArray(this.company[prop]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  public getDisplayForItem(prop: string, item: any): string {
    switch (prop) {
      case 'locations':
        return this.getDisplayForValue(item.location);
      case 'tags':
      default:
        return this.getDisplayForValue(item);
    }
  }

  public getDisplayForValue(item: unknown): string {
    if (item instanceof Object) {
      return JSON.stringify(item).replace(/","/gi, '", "');
    } else {
      return item as string;
    }
  }
  /** ********************************************************************/

  // private methods

  /**
   * We were told that the datalake ETL appends 2 characters to the end of the JForce IDs,
   * This strips those last 2 characters to extract the ID that can be used for JForce references.
   */
  private getJForceIDFromDataLakeID(datalakeID: string): string {
    return datalakeID.substring(0, datalakeID.length - 3);
  }

  private isDefined(v: unknown): boolean {
    if (typeof v === 'undefined' || v === null) {
      return false;
    }
    return true;
  }
}

enum Priority {
  low = 1,
  medium,
  high,
}
