import { Injectable } from '@angular/core';

// service imports
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';
import { Router } from '@angular/router';
import { AuthnService } from '../authn/authn.service';
import { UserRoles } from '../../../../../company-finder-common/src';
@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(
    protected context: DeploymentContext,
    protected router: Router,
    protected authnService: AuthnService
  ) {}

  public async logOnServer(
    message: string,
    logLevel: 'debug' | 'info' | 'warn' | 'error'
  ): Promise<void> {
    const stack = Error().stack;
    console.log(
      `Preparing log for server. Message: ${message} Stack: ${stack} `
    );
    this.sendToServer(message, stack, logLevel);
  }

  public async logErrorOnServer(
    error: Error,
    correlationId: string
  ): Promise<void> {
    try {
      await this.sendToServer(
        error.message,
        error.stack,
        'error',
        correlationId
      );
    } catch {
      // intentionally surpressing errors to prevent the handler from
      // overflowing the stack.
    }
  }

  private async sendToServer(
    message: string,
    stack: string,
    logLevel: 'debug' | 'info' | 'warn' | 'error',
    correlationId: string = ''
  ): Promise<void> {
    // This was put in as a response to ADJQ-1432 to resolve
    // circular dependencies. Although this was not the final
    // change to resolve this issue, it was decided that it is
    // probably best to use fetch rather thean the HttpClient for
    // this call so that error generated in the HttpClient,
    // interceptors, etc are more likely to make it up to the
    // server logs
    await fetch(`${this.context.buildApiUrl('/log')}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        isError: true,
        created: new Date(),
        clientUser: this.clientUser,
        stack: stack,
        message: message,
        logLevel: logLevel,
        origin: `${window.location.origin}${this.router.url}`,
        userAgent: navigator.userAgent,
        clientVersion: this.context.appVersion,
        correlationId: correlationId,
      }),
    });
  }

  private get clientUser(): { username: string; userRole: UserRoles } {
    return this.authnService.isAuthenticated
      ? {
          username: this.authnService.currentUsername,
          userRole: this.authnService.currentRole,
        }
      : { username: 'anonymous', userRole: null };
  }
}
